import React from "react"
import { Link } from "gatsby"
import "../css/typography.css"
import { rhythm } from "../utils/typography"
import { FaInstagram } from "react-icons/fa"
import { useStaticQuery, graphql } from "gatsby"
import Intro from "./intro.js"

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query Layout {
      site {
        siteMetadata {
          social {
            instagram
          }
        }
      }
    }
  `)

  const { social } = data.site.siteMetadata
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = <Intro title={title} />
  } else {
    header = (
      <div
        style={{
          backgroundColor: "#3e5336",
          margin: "auto",
          height: `60px`,
        }}
      >
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Link
              style={{
                boxShadow: `none`,
                color: `white`,
                fontFamily: "Girassol",
                fontSize: "2rem",
                margin: "0",
                padding: "0 10px",
              }}
              to={`/`}
            >
              {title}
            </Link>
          </div>
          <div
            style={{
              margin: "0",
              padding: "0 10px",
            }}
          >
            <a
              style={{
                textDecoration: `none`,
                boxShadow: `none`,
                color: `white`,
                display: "flex",
              }}
              href={`https://instagram.com/${social.instagram}`}
            >
              <FaInstagram size="30px"></FaInstagram>
            </a>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <header
        style={{
          color: "#3e5336",
        }}
      >
        {header}
      </header>
      <div
        style={{
          backgroundColor: "#f3e7dd",
          // backgroundColor: "#e9dcd1",
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: "10px",
          }}
        >
          <main>{children}</main>
        </div>
      </div>
      <footer>
        <div
          style={{
            backgroundColor: "#3e5336",
            margin: "auto",
            height: "60px",
            color: "white",
            fontSize: "0.8rem",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          &copy; All rights reserved 2020.
        </div>
      </footer>
    </div>
  )
}

export default Layout
