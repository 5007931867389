import React from "react"
import { Link } from "gatsby"
import "../css/typography.css"
import { rhythm } from "../utils/typography"
import lucia from "../../content/assets/profile-pic.jpeg"
// import sun from "../../content/assets/sun.png"

const Intro = ({ title }) => {
  return (
    <div
      style={{
        backgroundColor: "#ffeaa4",
        margin: "auto",
        paddingBottom: `${rhythm(3 / 4)}`,
      }}
    >
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1 / 2)} 0`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          style={{ marginBottom: "-50px" }}
          src={lucia}
          height="100%"
          width="100%"
          alt={"lucia in the pool"}
          data-pin-nopin="true"
        />
        <div style={{ display: "flex" }}>
          <Link
            style={{
              backgroundColor: "#3e5336",
              boxShadow: `none`,
              color: `white`,
              fontFamily: "Girassol",
              fontSize: "2rem",
              margin: "0",
              padding: "10px",
            }}
            to={`/`}
          >
            {title}
            {/* <img
              style={{ marginBottom: 0 }}
              src={sun}
              height="50"
              width="50"
              alt={"sun"}
            /> */}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Intro
